import './App.scss';

import {Switch, Route, useLocation, Redirect} from 'react-router-dom';
import {CSSTransition, SwitchTransition} from "react-transition-group";

import Panel from "./routes/Panel";
import Login from "./routes/Login";
import Policy from "./routes/Policy";
import {useEffect, useState} from "react";

import {auth} from './firebase'

function App() {
    let location = useLocation();
    const [authentication, setAuthState] = useState({
        authenticated: false,
        initializing: true
    });

    useEffect(() => auth.onAuthStateChanged(user => {
        if (user) {
            setAuthState({
                authenticated: true,
                initializing: false
            });
        } else {
            setAuthState({
                authenticated: false,
                initializing: false
            });
        }
    }), [setAuthState]);

    if (authentication.initializing) {
        return <></>;
    }
    return (
      <div className="App">
        <SwitchTransition mode="out-in">
          <CSSTransition
              timeout={300}
              key={(location.key) ? location.key : 0 }
              classNames="fade"
          >
            <Switch location={location}>
              <Route path="/policy" render={() => <Policy/> } />
              
              <Route path="/panel/:id" render={() => (
                  !authentication.authenticated ? (
                    <Redirect to="/"/>
                  ) : (
                    <Panel />
                  )
                )}/>

              <Route path="/panel" render={() => (
                  !authentication.authenticated ? (
                    <Redirect to="/"/>
                  ) : (
                    <Panel />
                  )
                )}/>
              <Route path="/" render={() => (
                  authentication.authenticated ? (
                    <Redirect to="/panel"/>
                  ) : (
                    <Login />
                  )
                )} />
            </Switch>
          </CSSTransition>
        </SwitchTransition>
      </div>
  );
}

export default App;
