import Delete from '../../assets/icons/delete.svg'
import Eye from '../../assets/icons/eye.svg';
import EyeSlash from '../../assets/icons/eye_slash.svg';
import Down from './../../assets/icons/down.svg';
import './messageitem.scss';

import { useSelector } from 'react-redux'
import {useEffect, useState} from "react";

import moment from 'moment';
import 'moment/locale/sv';
import {getLocale} from "../../utils/locale";
import config from '../../config';

function capitalize(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function MessageItem(props) {
    const locale = useSelector((state) => state.locale.value);

    const [date, setDate] = useState({
        hour_min: "",
        since: ""
    });

    const [active, setActive] = useState(props.data.active);

    const [more, setMore] = useState(false);


    const handleMore = () => {
        setMore(!more);
    };

    const handleDelete = () => {
        props.handleDelete(props.data);
    };

    const handleToggle = () => {
        props.handleToggle(props.data);
        setActive(!active);
    };

    useEffect(() => {
        moment.locale(locale);
        let d = new Date(props.data.createdAt._seconds * 1000);

        let REFERENCE = moment();
        let TODAY = REFERENCE.clone().startOf('day');
        let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        let A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        let FOUR_WEEK_OLD = REFERENCE.clone().subtract(28, 'days').startOf('day');
        let ONE_YEAR_OLD = REFERENCE.clone().subtract(365, 'days').startOf('day');

        let since;
        if(moment(d).isSame(TODAY, 'day')){
            since = getLocale(locale).date.today;
        }
        else if(moment(d).isSame(YESTERDAY, 'day')){
            since = getLocale(locale).date.yesterday;
        }
        else if(moment(d).isAfter(A_WEEK_OLD, 'day')){
            since = capitalize(moment(d).format('dddd'));
        }
        else if(moment(d).isBefore(A_WEEK_OLD, 'day') && moment(d).isAfter(FOUR_WEEK_OLD)){
            let weeks = TODAY.diff(moment(d), 'week');
            since = weeks + " " + ((weeks === 1) ? getLocale(locale).date.week : getLocale(locale).date.weeks);
        }
        else if(moment(d).isBefore(FOUR_WEEK_OLD, 'day') && moment(d).isAfter(ONE_YEAR_OLD)){
            since = capitalize(moment(d).format('MMMM'));
        }
        else{
            let years = TODAY.diff(moment(d), 'year');
            since = years + " " + getLocale(locale).date.year;
        }

        setDate({
           hour_min: String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0"),
           since: since
        });
    }, [locale]);

    return(
        (props.data)
            ?
            (<div className="MessageItem">
                <button onClick={handleDelete} className="btn-nostyle deletBtn">
                    <img src={Delete}  alt="Delete" />
                </button>

                <button onClick={handleToggle} className="btn-nostyle deletBtn">
                    {
                        active
                        ? (<img src={Eye}  alt="Hide" />)
                        : (<img src={EyeSlash}  alt="Show" />)
                    }
                </button>

                <p className="date">{ date.hour_min } - {date.since}</p>

                <h2>{props.data[locale].title}</h2>

                {
                    props.data[locale].message.length <= config.MSG_LENGTH
                        ? (
                            <p className="message">
                                {props.data[locale].message}
                            </p>
                        )
                        : (
                            <>
                                <p className={`message ${!more ? "limit" : ""}`}>
                                    {props.data[locale].message}
                                </p>
                                <p className={`more  ${more ? "active" : ""}`} onClick={handleMore}>
                                    {
                                        more
                                            ? getLocale(locale).message.less
                                            : getLocale(locale).message.more
                                    } <img src={Down} />
                                </p>
                            </>
                        )
                }

            </div>)
            : (<></>)
    )
}

export default MessageItem;
