import Globe from './../../assets/icons/globe.svg';
import Down from './../../assets/icons/down.svg';
import './langpicker.scss';

import {getLocale} from '../../utils/locale'

import { useSelector, useDispatch } from 'react-redux'

import {setLocale} from '../../store'

function LangPicker(props) {
    const locale = useSelector((state) => state.locale.value)
    const dispatch = useDispatch()

    return(
        <div className="LangPicker">
            {getLocale(locale).header.picker}
            <img src={Globe} />
            <img src={Down} />
            
            <div className="langpicker_dropdown">
                <ul>
                    <li className={`${locale == "sv" ? "selected" : "" }`} onClick={() => dispatch(setLocale("sv"))}>Svenska</li>
                    <li className={`${locale == "en" ? "selected" : "" }`} onClick={() => dispatch(setLocale("en"))}>English</li>
                </ul>
            </div>
        </div>
    )
}

export default LangPicker;
