import Logo from "../../assets/logo.png";
import Logo_blue from "../../assets/logo.svg";
import './header.scss';
import LangPicker from "../LangPicker";

import Logout from '../Logout';

function Header(props) {
    return(
        <div className="Header">
            {
                (props.loggedin) ?
                    <div className="headerHolder">
                        <img src={Logo_blue} className="logo" />

                        <LangPicker />

                        <Logout />
                        
                    </div>
                :   <img src={Logo} className="logo" />
            }

        </div>
    )
}

export default Header;
