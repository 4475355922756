import Header from "../components/Header";
import './panel.scss';
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import RightArrow from "../assets/icons/right_arrow_white.svg";
import {useContext, useEffect, useState} from "react";

import {getLocale} from '../utils/locale'

import MessageItem from "../components/MessageItem";
import {UserContext} from "../utils/UserProvider";

import { useSelector } from 'react-redux'

import config from './../config';

function Panel() {
    // Form
    const [formState, setformState] = useState({
        title_sv: "",
        title_en: "",
        message_sv: "",
        message_en: ""
    });

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    // Messages & loading
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastKey, setLastKey] = useState(null);
    const [loadedAll, setLoadedAll] = useState(false);

    // response
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    // Locale
    const locale = useSelector((state) => state.locale.value);

    // get user for token
    const user = useContext(UserContext);

    // For DELETE modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [deleteObj, setDeleteObj] = useState(null);

    // DELETE API
    const handleDelete = (data, index) => {
        setDeleteObj({...data, index: index});

        setShow(true);
    };

    const APIDelete = async () => {
        await user.getIdToken().then((token) => {
            fetch(`${config.API_URL}/${deleteObj.id}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json',
                        'authorization': "Bearer " + token,
                    }
                })
                .then(() => {
                    // Remove from array
                    messages.splice(deleteObj.index, 1);
                    setMessages([...messages]);
                })
                .catch(er => {
                    setSuccessMsg(null);
                    setErrorMsg(getLocale(locale).errors.remove_msg);
                })
        });
    };

    // ADD API
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMsg(null);
        setSuccessMsg(null);
        setLoadingSubmit(true);

        APIAdd();
    };

    const APIAdd = async () => {
        await user.getIdToken().then((token) => {
            fetch(config.API_URL,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        'authorization': "Bearer " + token,
                    },
                    body: JSON.stringify({
                        title_sv: formState.title_sv,
                        title_en: formState.title_en,
                        message_sv: formState.message_sv,
                        message_en: formState.message_en
                    })
                })
                .then(res => res.json())
                .then(data => {
                    if(data.message){
                        // Error
                        setErrorMsg(getLocale(locale).errors.missing_fields);
                        setLoadingSubmit(false);
                    }
                    else{
                        setformState({
                            title_sv: "",
                            title_en: "",
                            message_sv: "",
                            message_en: ""
                        });
                        setSuccessMsg(getLocale(locale).success.new_msg);
                        setMessages([data, ...messages]);
                        setLoadingSubmit(false);
                    }
                })
                .catch(er => {
                    setErrorMsg(getLocale(locale).errors.new_msg);
                    setLoadingSubmit(false);
                })
        });
    };

    // TOGGLE API
    const handleToggle = async (data, index) => {
        await user.getIdToken().then((token) => {
            fetch(`${config.API_URL}/toggle/${data.id}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json',
                        'authorization': "Bearer " + token,
                    }
                })
                .then(res => res.json())
                .then(data => console.log(data))
                .catch(er => {
                    console.log(er);
                })
        });
    };

    // Scroll
    const handleInfiniteScroll = async (e) => {
        let target = e.target;
        const OFFSET = 25;

        if(!loading && !loadedAll){
            if(target.scrollTop > (target.scrollHeight - target.offsetHeight - OFFSET)){
                // Load more content
                setLoading(true);

                await user.getIdToken().then(async (token) => {
                    await fetch(`${config.API_URL}-admin/${config.GET_LIMIT}/${lastKey}`,
                        {
                            method: "GET",
                            headers: {
                                "content-type": "application/json",
                                'authorization': "Bearer " + token,
                            }
                        })
                        .then(res => res.json())
                        .then(data => {
                            if (data.message)
                                return;

                            if (data.length > 0)
                                setLastKey(data[data.length - 1].id);
                            else
                                setLoadedAll(true);

                            setMessages(messages.concat(data));
                        })
                        .catch(er => {
                            console.log(er)
                        });

                    setLoading(false);
                });
            }
        }
    };

    useEffect(() => {
        async function fetchData(){
            await user.getIdToken().then(async (token) => {
                await fetch(`${config.API_URL}-admin/${config.GET_LIMIT}`,
                    {
                        method: "GET",
                        headers: {
                            "content-type": "application/json",
                            'authorization': "Bearer " + token,
                        }
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data.message)
                            return;

                        // Get last key
                        if (data.length > 0)
                            setLastKey(data[data.length - 1].id);
                        else
                            setLoadedAll(true);

                        // Set messages
                        setMessages(data);
                    })
                    .catch(er => {

                    });

                setLoading(false);
            });
        }

        fetchData();
    }, [loadingSubmit]);

    return (
        <div className="Panel">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Ta bort</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {deleteObj && (<>{getLocale(locale).modal.remove} <b>{deleteObj[locale].title}</b></>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        {getLocale(locale).modal.cancel}
                    </Button>
                    <Button variant="primary" className="btn-blue" onClick={() => {APIDelete(); handleClose();}}>
                        {getLocale(locale).modal.remove_btn}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Header loggedin/>
            <div className="contentHolder2">
                <Container>
                    <Row>
                        <Col xs={12} md={7} style={{position: "relative"}}>
                            <h1 className="hidden-sm">{getLocale(locale).panel.headers.send}</h1>
                            <Form onSubmit={!loadingSubmit ? handleSubmit : null}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{getLocale(locale).panel.form.label.title} <b>{getLocale(locale).panel.form.label.sv}</b></Form.Label>
                                            <Form.Control
                                                type="title_sv"
                                                placeholder="Skriv en titel"
                                                value={formState.title_sv}
                                                onChange={(e) => setformState({...formState, title_sv: e.target.value})}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{getLocale(locale).panel.form.label.message} <b>{getLocale(locale).panel.form.label.sv}</b></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Skriv meddelandet"
                                                style={{ height: '100px' }}
                                                value={formState.message_sv}
                                                onChange={(e) => setformState({...formState, message_sv: e.target.value})}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{getLocale(locale).panel.form.label.title} <b>{getLocale(locale).panel.form.label.en}</b></Form.Label>
                                            <Form.Control
                                                type="title_en"
                                                placeholder="Write a title"
                                                value={formState.title_en}
                                                onChange={(e) => setformState({...formState, title_en: e.target.value})}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>{getLocale(locale).panel.form.label.message} <b>{getLocale(locale).panel.form.label.en}</b></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Write the message"
                                                style={{ height: '100px' }}
                                                value={formState.message_en}
                                                onChange={(e) => setformState({...formState, message_en: e.target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {
                                    errorMsg &&
                                        (<div className="error_box">
                                            {errorMsg}
                                        </div>)
                                }

                                {
                                    successMsg &&
                                        (<div className="success_box">
                                            {successMsg}
                                        </div>)
                                }

                                <Button variant="primary" type="submit" disabled={loadingSubmit} className="btn-large">
                                    {
                                        !loadingSubmit
                                            ? getLocale(locale).panel.form.inputs.send
                                            : getLocale(locale).panel.form.inputs.loading
                                    }
                                    <img src={RightArrow} />
                                </Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={5} className="sentMessages">
                            <h1>{getLocale(locale).panel.headers.sent}</h1>
                            <div className="messageHolder" onScroll={handleInfiniteScroll}>
                                {
                                    messages.map((data, index) => {
                                        return <MessageItem data={data} handleDelete={(val) => handleDelete(val, index)} handleToggle={(val) => handleToggle(val, index)} key={index} />
                                    })
                                }
                                {
                                    loading && (<div className="loadingHolder"><Spinner animation="border" /></div>)
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Panel;
