import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
    apiKey: "AIzaSyBGE9yAry2tolzJgnbkP0N43zfm9Yozqr4",
    authDomain: "su-sakerhet.firebaseapp.com",
    projectId: "su-sakerhet",
    storageBucket: "su-sakerhet.appspot.com",
    messagingSenderId: "499577337099",
    appId: "1:499577337099:web:c2cc1c1e3124dfdbb21e0e",
    measurementId: "G-M3N60HRYEX"
});

export const auth = firebase.auth();
