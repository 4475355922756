import React from 'react';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import App from './App';
import UserProvider from "./utils/UserProvider";
import store from './store'
import { Provider } from 'react-redux'

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <UserProvider>
                <App />
            </UserProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
