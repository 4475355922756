import {Button, Form, Alert} from "react-bootstrap";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {auth} from "../../firebase";

import RightArrow from './../../assets/icons/right_arrow.svg';

import {getLocale} from './../../utils/locale';

import { useSelector } from 'react-redux'

function Login() {
    const [formState, setformState] = useState({});
    const history = useHistory();

    const [showError, setShowError] = useState(false);

    const locale = useSelector((state) => state.locale.value)

    const handleLogin = async (e) => {
        e.preventDefault();

        // No data set
        if(!formState.email && !formState.password)
            return;

        // Try to login
        await auth.signInWithEmailAndPassword(formState.email, formState.password)
            .then(() => {
                setShowError(false);

                // eslint-disable-next-line no-restricted-globals
                history.push('/panel')
            })
            .catch(error => {
                setShowError(true);
            });
    };

    return(
        <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{getLocale(locale).login.label.email}</Form.Label>
                <Form.Control type="email" placeholder={getLocale(locale).login.inputs.email} onChange={(e) => setformState({...formState, email: e.target.value})} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{getLocale(locale).login.label.password}</Form.Label>
                <Form.Control type="password" placeholder={getLocale(locale).login.inputs.password} onChange={(e) => setformState({...formState, password: e.target.value})} />
            </Form.Group>

            {
                showError ?
                (<div className="error_box">
                    {getLocale(locale).login.error}
                </div>)
                : (<></>)
            }

            <Button variant="primary" type="submit" className="btn-white btn-large">
                {getLocale(locale).login.inputs.login}
                <img src={RightArrow} />
            </Button>


        </Form>
    )
}

export default Login;
