import {useHistory} from "react-router-dom";
import {auth} from "../../firebase";

import './logout.scss';

import { useSelector } from 'react-redux'
import { getLocale } from "../../utils/locale";

function Logout() {
    const history = useHistory();
    const locale = useSelector((state) => state.locale.value);

    const handleLogout = async (e) => {
        e.preventDefault();
        
        await auth.signOut();

        history.push('/')
    };

    return(
        <div className="Logout">
            <a href="" onClick={handleLogout}>{getLocale(locale).header.logout}</a>
        </div>
    )
}

export default Logout;
