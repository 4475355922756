import { configureStore, createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const localeSlice = createSlice({
    name: 'locale',
    initialState: {
      value: (cookies.get("locale")) ? cookies.get("locale") : "sv",
    },
    reducers: {
      setLocale: (state, action) => {
        cookies.set("locale", action.payload);
        state.value = action.payload;
      },
    },
  });

export const { setLocale } = localeSlice.actions

export default configureStore({
    reducer: {
        locale: localeSlice.reducer
    },
})
