import './login.scss';
import LoginForm from "../components/LoginForm";
import Header from "../components/Header";


function Login() {
    return (
        <div className="Login">
            <Header/>
            <div className="contentHolder">
                <h1>SUs Säkerhetsapp</h1>

                <LoginForm />
                
            </div>
        </div>
    );
}

export default Login;
